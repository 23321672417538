<template>
  <div>
    <v-row>
      <v-col cols="12" offset-sm="3" sm="6">
        <privacyPolicyText />
      </v-col>
      <v-col cols="12" offset-sm="3" sm="6">
        <v-btn block outlined :to="{ name: 'Home' }">Vai al sito</v-btn>
      </v-col>
    </v-row>

    <!-- <appFeature /> -->
  </div>
</template>

<script>
import privacyPolicyText from "@/components/landings/blocks/privacyPolicy_text.vue";
import appFeature from "@/components/landings/blocks/appFeature.vue";

export default {
  components: {
    privacyPolicyText,
  },
  data: () => ({}),
};
</script>

<style>
</style>