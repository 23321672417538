<template>
  <div class="app_container">
    <div class="card_contents" @click="toggleCard()">
      <!-- <div class="card_contents" @click="toggleCard()" @mouseenter="toggleCard()"> -->
      <transition name="flip">
        <div
          v-bind:key="flipped"
          class="card"
          v-bind:style="{ height: card_height,background: back_color }"
          v-bind:class="flipped ? 'card-back' : 'card-front'"
        >
          <!-- FRONT CARD -->
          <div
            v-show="!flipped"
            class="text-center"
            v-bind:style="{ background: back_color }"
          >
            <v-row
              no-gutters
              class="read-status"
              style="position: absolute; z-index: 2"
            >
              <v-col cols="12" class="text-right">
                <v-icon v-if="already_see" color="green">mdi-check</v-icon>
                <v-icon v-if="!already_see" color="black"
                  >mdi-circle-small</v-icon
                >
              </v-col>
            </v-row>
            <div
              style="position: absolute; width: 100%; height: 80%"
              class="d-flex align-center justify-center flex-column"
            >
              <img
                class="pt-4"
                :src="img_path"
                :srcset="img_path"
                height="100px"
                contain
                style="background-position: initial !important"
                :alt="img_alt"
              />
              <div class="front_text" v-html="front_text"></div>
            </div>
            <div class="click_top_read_more_container">
              {{ $t("af_click_to_see_detail") }}
            </div>
            <!--  -->
          </div>
          <br />
          <!-- BACK CARD -->
          <div>
            <div
              class="back_text_title px-4"
              v-show="flipped"
              v-html="back_title"
            ></div>
            <div
              class="back_text"
              v-show="flipped"
              v-html="back_text"
              v-bind:class="$vuetify.breakpoint.smAndDown ? 'px-2' : 'px-12'"
            ></div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { gtag } from "@/gtag";

export default {
  props: {
    img_path: {
      type: String,
    },
    img_alt: {
      type: String,
    },
    front_text: {
      type: String,
    },
    back_title: {
      type: String,
    },
    back_text: {
      type: String,
    },
    analytics_event: {
      type: String,
    },
    back_color: {
      type: String,
      default: "white",
    },
  },
  components: {},
  computed: {
    card_height() {
      if (this.$vuetify.breakpoint.xs) {
        return "250px";
      } else if (this.$vuetify.breakpoint.sm) {
        return "200px";
      } else if (this.$vuetify.breakpoint.md) {
        return "200px";
      } else if (this.$vuetify.breakpoint.lg) {
        return "230px";
      } else if (this.$vuetify.breakpoint.xl) {
        return "250px";
      }
      return "250px"
    },
  },
  data: () => ({
    // card_faces: {
    //   front: 'The "First Computer Programmer"',
    //   back: "Ada Lovelace",
    // },
    flipped: false,
    can_flip: true,
    already_see: false,
    //
    timer_auto_back_flip: null,
    //
    first_flip: true,
  }),
  methods: {
    toggleCard: function () {
      if (this.can_flip) {
        this.flipped = !this.flipped;

        if (this.first_flip) {
          console.log("send GA flip event");
          gtag("event", "feature_view", {
            feature: this.analytics_event,
          });
        }

        this.first_flip = false;
        localStorage.setItem(
          "lp_feature_view_" + this.analytics_event,
          new Date().getTime()
        );

        this.can_flip = false;
        this.already_see = true;
        setTimeout(() => {
          this.can_flip = true;
        }, 1000);
        clearTimeout(this.timer_auto_back_flip);
        this.timer_auto_back_flip = setTimeout(() => {
          this.flipped = false;
          clearTimeout(this.timer_auto_back_flip);
        }, 10000);
      }
    },
  },
  mounted() {
    // console.log(this.back_color);
    const now = new Date().getTime();
    const last_feature_view = localStorage.getItem(
      "lp_feature_view_" + this.analytics_event
    );
    if (!last_feature_view) {
      return;
    }
    if (parseInt(last_feature_view) + 300000 > now) {
      this.already_see = true;
    } else {
      this.already_see = false;
    }
  },
  created() {},
  watch: {},
};
</script>

<style scoped>
.flip_card_message {
  font-size: 0.8rem;
  position: relative;
  bottom: 10;
}

.app_container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 0;
}

.card_contents {
  transition: all 0.3s ease;
  width: 100%;
}

.card {
  display: block;
  width: 100%;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  position: relative;
  color: #000;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
  will-change: transform;
}

.card-back {
  width: 100%;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}

.card-front {
  width: 100%;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}

.card_contents:hover {
  /* transform: scale(0.95); */
  background-color: rgba(45, 45, 45, 0.01);
}

.flip-enter-active {
  transition: all 0.6s ease-out;
}

.flip-leave-active {
  display: none;
}

.flip-enter,
.flip-leave {
  transform: rotateY(180deg);
  opacity: 0;
}
/*  */

.click_top_read_more_container {
  position: absolute;
  bottom: 0;
  font-size: 0.75rem;
  text-align: center;
  width: 100%;
  padding-bottom: 6px;
}
/*  */

.front_text {
  font-size: 0.8rem;
  font-weight: bolder;
  padding-top: 0.5rem;
}

.back_text_title {
  font-weight: bolder;
  font-size: 0.8rem;
  padding-bottom: 0.5rem;
}

.back_text {
  font-size: 0.8rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.read-status {
  position: absolute;
  width: 100%;
  padding-right: 5px;
  padding-top: 3px;
}
</style>